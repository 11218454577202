<template>
  <validation-observer ref="registers" v-slot="{ invalid }">
    <confirm-dialog
      ref="confirmDelete"
      title="Delete Register?"
    ></confirm-dialog>
    <v-data-table
      :headers="registerHeaders"
      :items="currentRegisters"
      class="elevation-1"
      :loading="registersLoading"
      :loading-text="`Loading... One moment`"
    >
      <template v-slot:top>
        <v-dialog v-model="registerDialog" max-width="75vw">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="handleRegisterAdd"
            >
              New Register
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ registerAction }} Register</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <validated-text-field
                      v-model="register.registerName"
                      label="Register Name"
                      rules="required"
                    ></validated-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validated-select-field
                      v-if="registerAction !== 'Edit'"
                      :items="registerUnitOfMeasures"
                      :item-text="(item) => item.registerUnitOfMeasureName"
                      v-model="register.registerUnitOfMeasure"
                      label="Register Unit Of Measure"
                      rules="required"
                      @change="handleSetRegisterUnitOfMeasure($event)"
                      :return-object="true"
                    ></validated-select-field>
                    <v-text-field
                      readonly
                      v-model="register.registerUnitOfMeasureName"
                      v-else
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-checkbox
                      v-model="register.isIgnored"
                      color="primary"
                      label="Ignore"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelDialog">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="handleSaveRegister"
                :disabled="invalid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn @click="handleRegisterEdit(item)" icon>
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleRegisterDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template></v-data-table
    ></validation-observer
  >
</template>

<script>
import ValidatedSelectFieldVue from "@/components/Fields/ValidatedSelectField.vue";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { ValidationObserver } from "vee-validate";
import api from "./../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectFieldVue,
    "validation-observer": ValidationObserver,
    "confirm-dialog": ConfirmDialog,
  },

  props: {
    registers: { type: Array, default: () => [] },
  },

  watch: {
    registers(newVal) {
      console.log("registers updated");
      this.currentRegisters = newVal;
    },
  },

  data() {
    return {
      currentRegisters: [],
      selectedRegisterUnitOfMeasure: null,
      register: {
        registerUnitOfMeasure: null,
        registerName: null,
        isIgnored: false,
        id: null,
      },
      registerAction: "Add",
      registerUnitOfMeasures: [],
      registerDialog: false,
      registersLoading: false,
      registerHeaders: [
        { text: "Register Name", value: "registerName" },
        {
          text: "Register Unit of Measure",
          value: "registerUnitOfMeasureName",
        },
        // { text: "End Uses Assigned", value: "hasRegisterEndUses"},
        { text: "Ignore?", value: "isIgnored" },
        { text: "Actions", value: "id", sortable: false },
      ],
    };
  },

  async created() {
    this.registerUnitOfMeasures = await api.getRegisterUnitOfMeasures();
    this.currentRegisters = this.registers;
  },

  methods: {
    async handleSaveRegister() {
      if (this.registerAction === "Add") {
        await this.createRegister();
      } else {
        this.updateRegister();
      }
    },

    async createRegister() {
      let valid = await this.$refs.registers.validate();
      if (valid === false) {
        return;
      }

      //this.register.id = Date.now();
      this.register.registerTypeId = 2; //registerType.monitor in API
      this.$emit("createregister", this.register);
      this.registerDialog = false;
    },

    async updateRegister() {
      let valid = await this.$refs.registers.validate();
      if (valid === false) {
        return;
      }

      this.$emit("updateregister", this.register);
      this.registerDialog = false;
    },

    async deleteRegister(register) {
      this.$emit("deleteregister", register);
      this.registerDialog = false;
    },

    cancelDialog() {
      this.registerDialog = false;
    },

    handleSetRegisterUnitOfMeasure(registerEndUseUnitOfMeasure) {
      this.register.registerUnitOfMeasure = registerEndUseUnitOfMeasure;
    },

    async handleRegisterAdd() {
      await this.$refs.registers.reset();
      this.selectedRegisterUnitOfMeasure = null;
      this.registerAction = "Add";
      this.register = {
        registerName: "",
        registerUnitOfMeasure: null,
        isIgnored: false,
        id: null,
      };
      this;
    },

    handleRegisterEdit(register) {
      this.registerAction = "Edit";
      this.register = { ...register };
      this.registerDialog = true;
    },

    async handleRegisterDelete(register) {
      const msg = `Are you sure you want to delete ${register.registerName}?`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.deleteRegister(register);
    },
  },
};
</script>