<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Egauge</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="meter.deviceName"
                label="Name"
                rules="required"
                ref="nameInput"
              ></validated-text-field
            ></v-col>

            <v-col sm="6">
              <validated-text-field
                v-model="meter.friendlyName"
                label="Display Name"
                rules="required"
              ></validated-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col sm="6" class="mt-3">
              <site-location-field
                v-if="finishedLoading"
                :siteId="siteId"
                :selectedSiteLocationId="meter.siteLocationId"
                @sitelocationselected="siteLocationSelected"
                ref="siteLocation"
              ></site-location-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidMeter">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="4" class="pa-4 mt-2">
          <v-tabs right v-model="active_tab">
            <v-tab :key="registerTab">Registers</v-tab>
            <v-tab-item :key="registerTab">
              <register
                :registers="registers"
                @createregister="createRegister"
                @updateregister="updateRegister"
                @deleteregister="deleteRegister"
              ></register>
            </v-tab-item>
          </v-tabs>
        </v-sheet>

        <v-row justify="center">
            <stepper-validate 
            :invalid="invalid"
            :meterName="meter.deviceName"
            :friendlyName="meter.friendlyName"
            :siteLocation="selectedSiteLocation"            
            @dismissdialog = "dismissDialog"
            @fixmeter = "fixMeterErrors"
            ref="stepper"></stepper-validate>
        </v-row>

        <v-row justify="center"> </v-row> </v-form></validation-observer
  ></v-container>
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import StepperValidate from "./StepperValidate.vue"
import Register from "./Register";
import api from "./../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "stepper-validate": StepperValidate,
    register: Register,
  },  

  data() {
    return {
      finishedLoading: false,
      valid: true,
      meter: {
        siteId: this.$route.params.id,
        deviceName: null,
        friendlyName: null,
        siteLocationId: null,
      },
      deviceName: null,
      friendlyName: null,
      siteLocations: [],
      selectedSiteLocation: null,
      isValidMeter: true,

      registerDialog: false,
      siteId: null,
      monitorId: null,

      active_tab: 0,
      registerTab: null,
      endUseTab: null,
      endUsePercentageTab: null,
      registers: [],
      endUses: [],
      endUsePercentages: [],
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.monitorId = this.$route.params.egaugeId;    
    try {
      await this.getLookups();
      this.finishedLoading = true;
    } catch (error) {
      console.log(error);
    }
  },

  computed: {},

  methods: {
    async getLookups() {
      this.meter = await api.getEgaugeById(this.monitorId);
      this.registers = await api.getRegistersForEgauge(this.monitorId);
    },

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.$refs.stepper.validateMeter();
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    //post the new register passed from the register component
    async createRegister(register) {
      register.siteId = this.siteId;
      register.monitorId = this.monitorId;
      register.registerUnitOfMeasureId = register.registerUnitOfMeasure.id;
      //update the registers collection after the post
      await api.createRegister(register);
      this.registers = await api.getRegistersForEgauge(this.monitorId);
    },

    async updateRegister(register) {
      await api.updateRegister(register);
      this.registers = await api.getRegistersForEgauge(this.monitorId);
    },

    async deleteRegister(register) {
      try {
        await api.deleteRegister(register);
        this.registers = await api.getRegistersForEgauge(this.monitorId);
      } catch (error) {
        console.log(error);
      }
    },

    //post the new register passed from the register component
    async createEndUse(endUse) {
      //update the endUses collection after the post
      this.endUses.push(endUse);
    },

    async updateEndUse(endUse) {
      const self = this;
      let updated = [];
      this.endUses.forEach(function (reg) {
        if (reg.id === endUse.id) {
          updated.push(endUse);
        } else {
          updated.push(reg);
        }
        self.endUses = updated;
      });
    },

    async deleteEndUse(endUse) {
      let updated = this.endUses.filter((r) => r.id !== endUse.id);
      this.endUses = updated;

      //we must also delete any percentages assigned to any deleted end uses
      let updatedPercentages = this.endUsePercentages.filter(
        (p) => p.endUse.id !== endUse.id
      );
      this.endUsePercentages = updatedPercentages;
    },

    async createPercentage(percentage) {
      //update the endUses collection after the post
      this.endUsePercentages.push(percentage);
    },

    async deletePercentage(percentage) {
      let updated = this.endUsePercentages.filter(
        (r) => r.id !== percentage.id
      );
      this.endUsePercentages = updated;
    },

    async updatePercentage(percentage) {
      const self = this;
      let updated = [];
      this.endUsePercentages.forEach(function (per) {
        if (per.id === percentage.id) {
          updated.push(percentage);
        } else {
          updated.push(per);
        }
        self.endUsePercentages = updated;
      });
    },

    fixMeterErrors(item) {
      switch (item) {
        case 1:
          this.$nextTick().then(() => {
            this.$refs.nameInput.focus();
          });
          break;
        case 2:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        default:
          break;
      }
    },

    async dismissDialog(isValid) {
      this.isValidMeter = isValid;
      if (isValid) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.meter.siteLocationId = this.selectedSiteLocation?.id;
          await api.updateEgauge(this.meter);
          this.$router.push({ name: "SubMeterEgaugeIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    handleCancelCrud() {
      this.$router.push({name: "SubMeterEgaugeIndex"});
    }
    
  },
};
</script>